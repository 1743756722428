"use strict";
/*!
 *
 *  local.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        this.URLPath = location.pathname + location.search;
        this.URLLocate = location.protocol + '//' + location.hostname;
        this.setting = {
            root: '/',
            not: undefined
        };
        this.$elem = $('.st-Local_List');
        this.$switch = this.$elem.find('.st-Local_Switch');
        this.setCurrent();
        this.setSwitch();
    }
    default_1.prototype.setCurrent = function () {
        var _this = this;
        this.$elem.find('a').each(function (i, elem) {
            if (_this.setting.not !== undefined && $(elem).is(_this.setting.not)) {
                return;
            }
            ;
            var href = $(elem).attr('href');
            href = href !== undefined ? href.replace(_this.URLLocate, '') : undefined;
            if (href !== undefined) {
                var isCurrent = (_this.URLPath.search(href) == 0);
                var _URL = _this.URLPath.replace(/(.+\/)(.+\.html)$/g, '$1');
                if (href === _this.setting.root) {
                    isCurrent = (_this.URLPath === href) || (href === _URL);
                }
                ;
                if (isCurrent) {
                    $(elem).attr('aria-current', 'page');
                    var $item = $(elem).closest('.st-Local_Item');
                    if (!$(elem).is('.st-Local_Link')) {
                        $item.find('.st-Local_Link').attr('aria-current', 'true');
                        $item.find('.st-Local_Switch').attr('aria-selected', 'true');
                        $item.find('.st-Local_Body').attr('aria-expanded', 'true').show(0);
                    }
                    ;
                }
                ;
            }
            ;
        });
    };
    default_1.prototype.setSwitch = function () {
        this.$switch.on('click', function (e) {
            e.preventDefault();
            var $item = $(e.currentTarget).closest('.st-Local_Item');
            var $link = $item.find('.st-Local_Link');
            if ($(e.currentTarget).is('[aria-selected="false"]')) {
                $(e.currentTarget).attr('aria-selected', 'true');
                if (!$link.is('[aria-current="page"]')) {
                    $link.attr('aria-current', 'true');
                }
                ;
                $item.find('.st-Local_Body').attr('aria-expanded', 'true').stop(true, false).slideDown(400, 'easeOutExpo');
            }
            else {
                $(e.currentTarget).attr('aria-selected', 'false');
                if (!$link.is('[aria-current="page"]')) {
                    $link.attr('aria-current', 'false');
                }
                ;
                $item.find('.st-Local_Body').attr('aria-expanded', 'true').stop(true, false).slideUp(400, 'easeOutExpo');
            }
            ;
        });
    };
    return default_1;
}());
exports.default = default_1;
