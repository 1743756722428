"use strict";
/*!
 *
 *  fancybox-defaults-setting.js
 *
 */
$.fancybox.defaults.animationDuration = 500;
$.fancybox.defaults.transitionDuration = 500;
$.fancybox.defaults.arrows = true;
$.fancybox.defaults.infobar = false;
$.fancybox.defaults.toolbar = true;
$.fancybox.defaults.buttons = ['close'];
$.fancybox.defaults.smallBtn = false;
$.fancybox.defaults.wheel = false;
$.fancybox.defaults.transitionEffect = "slide";
$.fancybox.defaults.idleTime = 999999;
// $.fancybox.defaults.spinnerTpl = `
// 	<div class="sw-Loading">
// 		<div class="sw-Loading_Item"></div>
// 		<div class="sw-Loading_Item"></div>
// 		<div class="sw-Loading_Item"></div>
// 		<div class="sw-Loading_Item"></div>
// 		<div class="sw-Loading_Item"></div>
// 		<div class="sw-Loading_Item"></div>
// 		<div class="sw-Loading_Item"></div>
// 		<div class="sw-Loading_Item"></div>
// 		<div class="sw-Loading_Item"></div>
// 		<div class="sw-Loading_Item"></div>
// 		<div class="sw-Loading_Item"></div>
// 		<div class="sw-Loading_Item"></div>
// 	</div>
// `;
