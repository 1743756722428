"use strict";
/*!
 *
 *  page2top.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$elem = $('#Page2Top');
        this.$wrapper = $('.st-Wrapper');
        this.isStatic = false;
        // isVisible: boolean = false;
        this.borderLine = 0;
        $(window).on('scroll', function (e) {
            var top = $(e.currentTarget).scrollTop();
            var bottom = top + util.wh;
            // if (!this.isVisible && top > 0) {
            // 	this.isVisible = true;
            // 	this.$elem.addClass('-visible');
            // };
            // if (this.isVisible && top <= 0) {
            // 	this.isVisible = false;
            // 	this.$elem.removeClass('-visible');
            // };
            if (!_this.$elem.is('.-show') && top > 0) {
                _this.$elem.addClass('-show');
            }
            ;
            if (_this.$elem.is('.-show') && top <= 0) {
                _this.$elem.removeClass('-show');
            }
            ;
            if (!_this.isStatic && bottom >= _this.getBorder()) {
                _this.isStatic = true;
                _this.$elem.addClass('-static');
            }
            ;
            if (_this.isStatic && bottom < _this.getBorder()) {
                _this.isStatic = false;
                _this.$elem.removeClass('-static');
            }
            ;
        });
    }
    default_1.prototype.getBorder = function () {
        var top = 0;
        if (util.viewport === 'phone' || util.viewport === 'tablet') {
            top = this.$elem.outerHeight();
        }
        ;
        return this.$wrapper.offset().top + this.$wrapper.outerHeight() + top;
    };
    return default_1;
}());
exports.default = default_1;
