"use strict";
/*!
 *
 *  load-images.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
function load(src) {
    return new Promise(function (resolve, reject) {
        var $img = $('<img />').attr('src', src + '?_=' + Date.now());
        // console.log(src);
        // let $img = $('<img />').attr('src', src);
        $img.on('load', function (e) {
            resolve(src);
        });
    });
}
;
exports.default = (function (src) {
    var loades = [];
    if (typeof src === 'string') {
        loades.push(load(src));
    }
    else {
        src.forEach(function (val, i) {
            loades.push(load(val));
        });
    }
    ;
    return Promise.all(loades);
});
