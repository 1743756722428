"use strict";
/*!
 *
 *  more.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(elem) {
        this.max = 0;
        this.show = 10;
        this.add = 10;
        this.$btn = $(elem);
        this.$news = this.$btn.prev('.-more');
        this.$items = this.$news.children();
        this.max = this.$items.length;
        if (this.$news.is('.news-Events')) {
            this.show = 5;
            this.add = 5;
        }
        ;
        if (this.show >= this.max) {
            this.$btn.hide(0);
        }
        ;
        this.init();
    }
    default_1.prototype.init = function () {
        var _this = this;
        this.$btn.children('a').on('click', function (e) {
            e.preventDefault();
            _this.show += _this.add;
            _this.$news.trigger('showMore');
            if (_this.show >= _this.max) {
                _this.$btn.hide(0);
            }
            ;
        });
        this.$news.on('showMore', function () {
            _this.$items.each(function (i, item) {
                if (i < _this.show) {
                    $(item).addClass('-show');
                }
                ;
            });
        });
    };
    return default_1;
}());
exports.default = default_1;
