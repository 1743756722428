"use strict";
/*!
 *
 *  menu.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropDown = exports.Menu = void 0;
var Menu = /** @class */ (function () {
    function Menu() {
        var _this = this;
        this.$menu = $('.st-Menu');
        this.$btn = $('.st-Menu_Btn');
        this.top = 0;
        this.checkViewport();
        // $(window).on('viewportChanged', () => {
        // 	this.checkViewport();
        // });
        this.$btn.on('click', function (e) {
            e.preventDefault();
            var $menu = $(e.currentTarget).closest('.st-Menu');
            if ($menu.is('[aria-expanded="true"]')) {
                $menu.attr('aria-expanded', 'false');
                $('html').css({
                    overflow: ''
                });
                document.removeEventListener('touchmove', _this.scrollControll);
            }
            else {
                $menu.attr('aria-expanded', 'true');
                $('html').css({
                    overflow: 'hidden'
                });
                document.addEventListener('touchmove', _this.scrollControll, {
                    passive: false
                });
            }
            ;
        });
    }
    Menu.prototype.scrollControll = function (e) {
        var $inner = $(e.target).closest('.st-MegaMenu_Main_Inner');
        if ($inner.length > 0) {
            if ($inner.get(0).scrollHeight > $inner.innerHeight()) {
                e.stopPropagation();
            }
            else {
                e.preventDefault();
            }
            ;
        }
        else {
            e.preventDefault();
        }
    };
    Menu.prototype.checkViewport = function () {
        // this.$menu.removeClass('-transition');
        // if (util.viewport !== 'phone') {
        // 	this.$menu.removeAttr('aria-expanded');
        // } else {
        // 	this.$menu.attr('aria-expanded', 'false').addClass('-transition');
        // };
        this.$menu.addClass('-transition');
    };
    return Menu;
}());
exports.Menu = Menu;
;
var DropDown = /** @class */ (function () {
    function DropDown() {
        var _this = this;
        this.$switch = $('.st-Global_Switch');
        this.$switch.on('click', function (e) {
            e.preventDefault();
            var $target = $('#' + $(e.currentTarget).attr('aria-controls'));
            if ($(e.currentTarget).attr('aria-selected') === 'false') {
                $(e.currentTarget).attr('aria-selected', 'true');
                var h = $target.children().outerHeight();
                $target.attr('aria-expanded', 'true').css('height', h);
                // $target.attr('aria-expanded', 'true').stop(false, true).slideDown(500, 'easeOutQuart');
            }
            else {
                $(e.currentTarget).attr('aria-selected', 'false');
                $target.attr('aria-expanded', 'true').css('height', 0);
                // $target.attr('aria-expanded', 'false').stop(false, true).slideUp(500, 'easeOutQuart');
            }
            ;
        });
        this.switch();
        $(window).on('viewportChanged', function () {
            _this.switch();
        });
    }
    DropDown.prototype.switch = function () {
        if (util.viewport !== 'phone') {
            this.$switch.each(function (i, elem) {
                var $target = $('#' + $(elem).attr('aria-controls'));
                var h = $target.children().outerHeight();
                $(elem).attr('aria-selected', 'true');
                $target.attr('aria-expanded', 'true').css('height', h);
            });
        }
        else {
            this.$switch.each(function (i, elem) {
                var $target = $('#' + $(elem).attr('aria-controls'));
                $(elem).attr('aria-selected', 'false');
                $target.attr('aria-expanded', 'false').css('height', 0);
            });
        }
        ;
    };
    return DropDown;
}());
exports.DropDown = DropDown;
